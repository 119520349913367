










































































































































































































































































@import "~vars";

.vuedp-date-picker__calendar,
button,
input {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}

// Bootstrap sr-only
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.vuedp-date-picker__calendar {
  position: absolute;
  z-index: 30;
  background-color: #fff;
  width: 300px;
  border: 1px solid #ccc;

  /** General **/
  .cell {
    width: percentage(1/7);
    text-align: center;
    line-height: 40px;
    border: 1px solid transparent;
    box-sizing: border-box;
  }

  /** Header controls **/
  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    line-height: 40px;

    button {
      display: inline-block;
      flex: 1 0 auto;
      background: #fff;
      padding: 00;
      border: none;
      outline: none;

      &:hover {
        background: #eee;
      }
    }

    .date-picker-nav__prev,
    .date-picker-nav__next {
      flex: 0 1 40px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        border: 6px solid transparent;
      }
    }

    .date-picker-nav__prev::after {
      border-right: 10px solid #000;
      margin-left: -5px;
    }
    .date-picker-nav__next::after {
      border-left: 10px solid #000;
      margin-left: 5px;
    }
  }

  /** Weekdays **/
  .weekdays {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;

    .weekday {
      font-size: 0.75em;
    }
  }

  .days {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .cell {
      cursor: pointer;

      &:hover {
        border-color: #01a1ef;
      }
      &.prev-month,
      &.next-month {
        opacity: 0.55;
      }
      &.highlighted {
        background: lighten(#01a1ef, 20);
        color: #fff;
      }
      &.selected {
        color: #000;
        border: 2px solid #58c8fe;
      }
      &.disabled {
        background-color: transparent !important;
        color: #dadada;
        cursor: default;

        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  .time-scale {
    display: flex;
    align-items: center;
    padding: 1.25rem 0;

    &.box {
      margin-bottom: 0;
    }

    .time-label {
      margin-right: auto;
    }
  }

  .colour-legenda li {
    padding-left: 0.5rem;
    height: 1.5rem;
    line-height: 1.5rem;

    &.combi::before,
    &.contact::before,
    &.speed::before {
      content: '';
      height: 1.5rem;
      width: 1.5rem;
      display: block;
      margin-right: 0.5rem;
      float: left;
      background-color: $grey;
    }

    &.combi::before {
      background-color: $cyan;
    }

    &.contact::before {
      background-color: $danger;
    }

    &.speed::before {
      background-color: $yellow;
    }
  }
}
