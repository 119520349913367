






























































































































































































@import "~vars";

.navbar {
  flex-shrink: 0;
  padding-bottom: 1rem;
}

.navbar-item {
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: left;
}

.logo {
  pointer-events: bounding-box;
  z-index: 1;
}

.logo .plus {
  transition: transform 0.2s ease-in;
  transform-origin: 80% 20%;

  .is-hover &{
    transform: rotate(90deg);
  }
}

.logo-home {
  position: absolute;
  opacity: 0;
  transform: translateY(0);
  transition: transform 0.2s ease-in;
  transition-property: transform, opacity;
}

.is-hover .logo-home {
  opacity: 1;
  transform: translateY(40px);
}

.nav-icon {
  cursor: pointer;
  width: 32px;
  height: 32px;

  &.has-counter {
    position: relative;

    .counter {
      position: absolute;
      right: 0px;
      top: 0px;
      transform: translate(50%, -38%);

      .badge {
        position: relative;
        min-height: initial;
      }
    }
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

.user_nav_icon_holder {
  display: inherit;
  position: relative;

  .badge {
    position: absolute;
    right: -0.5rem;
    top: -0.30rem;
  }
}

.new-label {
  margin-left: 1rem;
  color: $cyan;
}

.badge {
  width: 1rem;
  display: inline-block;
  text-align: center;
  background-color: #000;
  color: #fff;
  font-size: 0.6875rem;
  border-radius: 50%;
  line-height: 1rem;
  min-height: 1rem;
  margin-left: 5px;

  &.is-primary {
    background-color: $cyan
  }
}
@media screen{
.printpage-head{
    visibility: hidden;
    position: absolute;
    border: solid;
}
}

//mobile and tablet title hidden
@media screen and (max-width: 1023px){
.title.extra{
  visibility: hidden;
  position: absolute;
  border: solid;
}
}
