@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";
// 2. Set your own initial variables
// Update blue
$blue: #4C93CE;
$cyan: #009DE0;
$magenta: #C4007A;
$yellow: #f29400; //for warnings
$background-yellow: #FFED00; //used as yellow not for warning

$orange: #f44336;

$aqua: #A0DCF4;
$light-blue: #F2F7FB;

$radius: 0;

$grey-lightest: #f3f3f3;
$grey-light: #dadada;
$grey-lighter: #808080;
$grey: #4A4A4A;
$grey-darker: #262525;
$grey-darkest: #000;

// Add a serif family
$family-serif: "Merriweather", "Georgia", serif;
$family-title: "Tomica", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$family-sans-serif:  "Tomica", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
// 3. Set the derived variables
// Use the new pink as the primary color
$primary: $black;

$secondary: $grey-darker;
$secondaryInvert: #fff;

$info: $blue;

$title-color: $primary;
$title-weight: 700;
$subtitle-color: $primary;

$link: $primary;

$body-weight: $weight-normal;

// Button Variables
$button-color: $primary;
$button-text-color: $primary;
$button-border-color: $primary;
$button-border-width: 2px;
$button-hover-color: $primary;
$button-padding-horizontal: 1.5rem;

// Card Variables

// Navbar variables
$navbar-height: 6.22rem;
$navbar-item-img-max-height: 3.1rem;
$navbar-item-color: $primary;
// $navbar-z: 1000;
$navbar-background-color: transparent;

$navbar-item-active-color: $black;
$navbar-item-hover-color: rgba($link, 0.6);
$navbar-dropdown-item-active-color: $link;

$navbar-item-hover-background-color: transparent;
$navbar-item-active-background-color: transparent;
$navbar-dropdown-item-hover-background-color: transparent;
$navbar-dropdown-item-active-background-color: transparent;
$navbar-dropdown-item-hover-color: $secondary;
$navbar-dropdown-item-active-color: $secondary;

$navbar-dropdown-boxed-radius: 0;
$navbar-dropdown-border-top: none;

// Table Variables
$table-color: $primary;
$table-striped-row-even-background-color: rgba($grey-darker, 0.05);
$table-cell-border: 1px solid $grey-lighter;
// $table-cell-border: none;
$table-cell-padding: 0.7em 2em;


// Use the existing orange as the danger color
$danger: $orange;
// Use the new serif family
$family-primary: $family-sans-serif;

$body-size: 15px;

// Title sizes
$size-1: 	5rem;  // 80
$size-2: 	3rem; // 48
$size-3: 	2rem; // 32
$size-4: 	1.5rem; // 24
$size-5: 	1.25rem; // 20
$size-6: 	1rem; // (16);
$size-7: 	0.875rem; // 14
$size-8:    0.66rem;

$letter-spacing: 1.5px;

// 4. Setup your Custom Colors
$linkedin: #0077b5;
$linkedin-invert: findColorInvert($linkedin);
$twitter: #55acee;
$twitter-invert: findColorInvert($twitter);
$github: #333;
$github-invert: findColorInvert($github);

// 5. Forms
$input-color: $grey;
$label-weight: 700;
$label-color: $primary;
$input-hover-border-color: $cyan;
$input-focus-border-color: $cyan;
$input-shadow: none;
$input-color: $primary;
$input-focus-box-shadow-size: 0;

// Box
// $box-background-color: $white;
// $box-color: $secondary;
// $box-padding: 0;
// $box-shadow: 0 2px 3px rgba($black, 0.05), 0 0 0 1px rgba($black, 0.05);
// $box-link-hover-shadow: 0 15px 60px rgba(10, 10, 10, 0.2), 0 0 0 1px rgba(10, 10, 10, 0.1);

// Generic
// $background: $light-blue;
$border: $aqua;

$light-border: 1px solid rgba(0,0,0, 0.05);

// Tag
$tag-color: $primary;
$tag-background-color: $white;
$tag-radius: 4px;
$tag-delete-margin: 0;

// Modal
$modal-background-background-color: rgba($black, 0.4);
$modal-card-head-background-color: $grey-light;
$modal-card-body-background-color: $grey-light;
$modal-content-width: 480px;
$modal-card-head-radius: 0;
$modal-card-foot-radius: 0;
$modal-card-foot-border-top: none;
$modal-card-head-border-bottom: none;
$modal-card-title-size: $size-2;
$modal-card-head-padding: 2rem;

// Panel
$panel-heading-weight: $weight-bold;

@import "~bulma/sass/utilities/derived-variables.sass";