


















































































@import "~vars";

.finish-extra {
  align-items: flex-start;

  .finish-select {
    flex: 0 0 50%;

      @media screen and (max-width: $tablet) {
      flex: 1;
      }
  }
  .v-select .vs__dropdown-toggle {
    border: none;
  }

  .vs__open-indicator {
    color: $black;
    fill: $black;
  }

  .vs__search::placeholder {
    opacity: 1;
  }

  .extra {
    height: 100%;
  }
}
