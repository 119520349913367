.button-row {
  margin-top: 1rem;
  .button {
    margin-right: 2rem;
  }
}

button.icon {
  padding: 4px;
  border: none;
  background-color: transparent;
  border-radius: 50%;
  margin: 0 4px;

  &:hover {
    cursor: pointer;
    background-color: rgba(10, 10, 10, 0.3);
  }
}

.button {
  border-radius: 0;

  &.is-borderless {
    border: none!important;
  }

  &.is-text {
    text-decoration: none;
    font-weight: $body-weight;

    &:hover, &.is-selected {
      background-color: transparent;
      text-decoration: underline;
    }

    &.is-selected {
      font-weight: 700;
    }
  }
}