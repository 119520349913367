




















































































































































































.copies-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .excel-section {
    display: flex;
    flex-direction: column;

    .button.is-text {
      padding-left: 2rem;
    }
  }
}

.total-counter {
  margin-top: 3rem;
}

@media screen and (max-width: 922px){
  .total-counter{
    font-size: large;
  }
}
