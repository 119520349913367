.breadcrumb {
    min-height: 1.5rem;

    .category-wrapper & {
        margin-top: -3rem;
        z-index: 99999;
        position: relative;
    }
}

.order-breadcrumbs {
    ul {
        flex-direction: column;
    }

    li {
        color: $grey-lighter;
        font-weight: bold;
        line-height: 2em;

        a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: inherit;
        }

        .icon {
            margin-right: 0.75rem;
        }
    }

    svg {
        color: $grey-lighter;
    }

    .is-active {
        color: $black;
        svg {
            color: $black;
        }
    }

    a:hover {
        color: $black;
        svg {
            color: $black;
        }
    }
}