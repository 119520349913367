






















































































































@import "~vars";

.loading {
  position: relative;
  min-width: 400px;
  min-height: 400px;
  padding: 7rem;
  display: flex;
}

#loader {
  display: flex;
  justify-content: center;
}

.loader-position:not(.is-holidays) {
  position: absolute;
  width: 200px;
  height: 50px;

  &.position-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.position-right {
    top: 50%;
    right: 0;
    transform: translate(-50, 0);
  }

  &.position-right-bottom {
    bottom: 0;
    right: 0;
  }

  #holiday {
    width: 300px;
    height: 250px;
  }
}

.loader-position.is-holidays {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  max-width: 350px;
}

#loader .dot {
  width: 25px;
  height: 25px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 6px;
  border: 2px solid #000;
  background-color: $black;

  &:first-child {
    border-color: $cyan;
    background-color: $cyan;
  }

  &:nth-child(2) {
    border-color: $magenta;
    background-color: $magenta;
  }

  &:nth-child(3) {
    border-color: $background-yellow;
    background-color: $background-yellow;
  }
}
