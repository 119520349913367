










































































































































































































































































































































































































































































.partnerButton {
  width: 100%;
  overflow: hidden;
  justify-content: flex-start;
}
