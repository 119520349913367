





















































































@import "~vars";
@import "~bulma/sass/utilities/mixins";

@include mobile() {
  main.main {
    padding: 0;
  }
}

#app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

main.main {
  flex: 1;
  padding-top: 0;
}

.loading-home {
  background-color: rgba($white, 0.8);
  position: fixed;
  height: 50vh;
  width: 50vh;
  z-index: 10000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
