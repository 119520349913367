





























































































@import "~vars";
@import "~bulma/sass/utilities/mixins";

.is-favorite {
  fill: $cyan;
  color: $cyan;
}

.productinfo.box {
  position: fixed;
  z-index: 9999;
  top: 0;
  overflow: scroll;
  bottom: 0;
  left: 0;
  right: 0;
  // do not show in mobile 
  @include mobile() {
    display: none;
  }

  @include tablet() {
    top: inherit;
    max-height: 250px;
  }

  @include widescreen() {
    left: inherit;
    max-height: none;
    width: 700px;
    height: 350px;
  }

  .productinfo-head {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease-out, opacity 0.3s ease-out;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(25%);
  opacity: 0;
}
