
























































.copies__view {
  font-size: 0.9rem;
  display: inline-table;
}

.truncate__frame {
  max-width: 7rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: lowercase;
}
