
































































































/* borders weghalen */
@import "~vars";

.finish {
  display: flex;
  align-items: center;
}

.finish-select {
  flex: 1 0 auto;
  max-width: 380px;
}

.level-2:first-child {
  border-top: 1px solid red;
}
