

































.partners {
}

.partner {
  text-align: center;

  .button {
    width: 100%;
  }
}
