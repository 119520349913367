



























































































































































































.mobile-editor{
  min-width: fit-content;
}

.field{
  display: flex;
  .help{
    flex: 50%;
  }
}


.printdetails{
  padding-bottom: 2rem;
  font-size: large;
}

.copy-row__buttons{
  padding-top: 0.5rem;
  display: flex;

  .button{
    flex-grow: 1;
    min-width: 6.6rem;
  }
}

.printrow{
  padding-bottom: 1rem;
}

.button.is-static{
  width: 10px;
  background-color: white;
  border: none;
}
.control{
  margin-bottom: 1.5rem;
}

@media screen{
.columns{
  display:inline !important;
}
}
