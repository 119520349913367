









































@import "~vars";
@import "~bulma/sass/utilities/mixins";

.product {
  max-width: fit-content;
  button {
    width: 100%;
  }
}

@include desktop() {
  .product-name {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-align: left;
    display: inline-block;
  }
}
