.card.mobile{
    .title{
        font-size:x-large;
        
    }
    .subtitle{
        padding-top: 1rem;
        display: flex;
        min-height: 4.5rem;
        gap: 1rem;
        font-size: medium;
        .leftbox{
            width: auto;
        }
        .rightbox{
            min-width: fit-content;
            margin-left: auto;
        }
    }

    
}