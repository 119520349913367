.tags.has-addons {
  flex-wrap: nowrap;
}

.tag:not(body) {
  border: none;

  &.is-delete.is-link:hover, &.tag:not(body).is-delete.is-link:focus {
    &::after, &::before {
      background-color: $black;
    }
  }
}
