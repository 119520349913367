


























































// mobile
@media screen and (max-width: 768px) {
.qr-button{
  bottom: 7rem !important;
}
}

.qr-button{
  position: fixed;
  bottom: 5rem;
  right: 0.5rem;

}

.dropdown-menu {
  margin-right: 2.5rem;
}

