
























































































































.flags {
  display: flex;
  position: relative;

  .flag {
    cursor: pointer;
    margin: 0 1rem;
    opacity: 1;
  }

  .flag-choice {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
  }

  .flag-wrapper {
    flex: 0 0 20%;
  }

  > .flag-wrapper.is-opened:not(.end-branch) > .flag-choice {
    width: 100%;
    flex-direction: row;

    &.first-level-only {
      flex-direction: column;
    }

    .first-level-only {
      left: 270px;
    }
  }

  .not-opened,
  .is-opened > .flag {
    opacity: 0;
    transform: translateY(-25%);
    transition: all 0.4s ease-in-out;
    visibility: hidden;
  }

  .first-level-only .flag-wrapper.not-opened {
    opacity: 1;
    visibility: visible;
    transform: none;
}
}
