





























































































@import "~vars";
@import "~bulma/sass/utilities/mixins";
.buttons.categories {
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.category-list {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.assortment.button {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.icon svg.small {
  width: 1rem;
}

.buttons.categories > .button {
  position: unset;
}

.button.is-text.is-selected h2 {
  font-weight: bold;
}

@include mobile() {
  .category.not-opened {
    display: none;
  }
}

@include tablet() {
  .entry-category {
    max-height: 250px;
    width: 100%;
  }

  .not-opened {
    max-width: 250px;
  }

  .category:not(.entry-category) {
    .slide {
      // transform: translate(-70px);
    }

    .category-list {
      position: absolute;
      top: 0;
      left: 270px;
      transition: transform 0.1 ease;
    }
  }
}

@include desktop() {
  .entry-category {
    max-height: 210px;
  }
}

@include widescreen() {
  .entry-category {
    max-height: inherit;
    overflow: inherit;
  }
}

.product {
  &-enter-active,
  &-leave-active {
    transition: all 0.2s ease-in;
    position: absolute;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateX(-250px);
  }
}
