@font-face {
  font-family: 'Tomica';
  src: local('Tomica'),
  url('../fonts/Tomica.woff2') format('woff2'),
  url('../fonts/Tomica.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tomica';
  src: local('Tomica Light'), local('Tomica-Light'),
  url('../fonts/Tomica-Light.woff2') format('woff2'),
  url('../fonts/Tomica-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Tomica';
  src: local('Tomica Bold'), local('Tomica-Bold'),
  url('../fonts/Tomica-Bold.woff2') format('woff2'),
  url('../fonts/Tomica-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

