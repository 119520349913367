







































































































@import "~vars";

.vuedp-date-picker__calendar .cell {
  &.combi {
    background-color: $cyan;
    color: $white;
  }
  &.contact:not(.disabled) {
    background-color: $danger;
    color: $white;
  }

  &.speed {
    background-color: $yellow;
    color: $white;
  }
}
