



































































































@import "~vars";
@import "~bulma/sass/utilities/mixins";

.finishes {
  display: flex;

  .icon svg {
    width: 2rem;
    height: 2rem;
  }

  .selection {
    width: 100%;

    @include desktop() {
      flex: 1 0 40%;
    }
  }
}

.finish-extras {
  margin-top: 4rem;
}

.finishes-sort {
  margin-top: 2rem;
}

.preview {
  margin-top: -100px;
  padding-left: 3rem;
  margin-right: -3rem;
}

.disclaimer{
  p{
    border-style: dashed;
    padding: 1.5rem;
    max-width: 40rem;
  }
}
