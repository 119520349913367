.input {
  border: none;
  border-bottom: 1px solid #000;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

label.button {
  input {
    display: none;
    width: 0;
  }
}

.v-select.dropdown {
  display: block;
}

.select.is-arrowless::after {
  content: none !important;
}

.delete {
  background-color: transparent;
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;
  width: 30px;
  height: 30px;

  &::before, &::after {
    background-color: $black;
  }

  &::before {
    height: 4px;
  }

  &::after {
    width: 4px;
  }
}