

















































































































































.cart-item {
  padding: 0 0 1rem 0;
}
.modal-content.modal__edit-order {
  width: 80%;
  //mobiel and tablet with 100%
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.cart-item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #000;
  padding: 1rem 0;

  .extra_reference {
    max-width: 240px;
    height: 2rem;
    margin: 0 1rem;
    padding: 0;
  }

  &__icon {
    transition: transform 0.2s ease-in-out;
    width: 2rem;
  }

  .cart-item__header {
    display: flex;
    justify-content: space-between;

    &-info {
      display: flex;
      flex: 1 0 auto;
      height: 2rem;
      align-items: center;
    }

    .price {
      margin-left: 1rem;

      .frame-price {
        margin-left: 1rem;
      }
    }

    &-buttons {
      flex: 0 0 auto;
      display: flex;
      justify-content: flex-end;
      height: 2rem;
    }
  }

  .cart-item__overview {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    flex-wrap: wrap;

    &.is-collapsed {
      padding: 0 18px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
    }

    .cart-item__preview {
      width: 300px;
      align-self: flex-start;
    }
    .cart-item__extra{
      padding: 4rem;
      align-self: flex-start;
    }

    .cart-item__copies {
      width: 50%;
      @media (max-width: 758px) {
        width: 100%;
      }
    }

    .cart-item__frames {
      flex: 1 0 100%;
      max-width: 60%;
      margin-top: 1rem;
    }
  }

  &.has-arrow .cart-item__overview {
      padding-left: 2rem;
  }
}

