







































































































@import "~vars";
@import "~bulma/sass/utilities/mixins";
$maxCheckoutWidth: 1000px;

@include until($desktop) {
  .cart-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
}

.total-prints,
.total {
  padding: 2rem 0;
}

.cart-items {
  border-bottom: 1px solid #000;
}

.cart-view {
  border: 1px solid #ccc;
  max-width: $maxCheckoutWidth;
}

.checkout-row {
  max-width: $maxCheckoutWidth;
  display: flex;
  justify-content: space-between;

  .checkout-button{
    padding: 1rem;
  }

}
