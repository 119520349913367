











































































































.screen{
  padding-top: 2rem;
}

@media (max-width: 1508px) {
  .column.column-breadcrumbs {
    display: none;
  }
}
@media screen and (max-width: 922px) {
  .screentitle{
    font-size: xx-large;
  }

  .button-row{
    display: flex;
    .button{
      flex-grow: 1;
    }
  }

}
