@include mobile() {
  .table {
    overflow-x: scroll;
    display: block;
  }
}

.is-small .table {
  overflow-x: scroll;
  display: block;
}