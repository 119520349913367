.c-magenta {
    color: $magenta!important;
}

.c-cyan {
    color: $cyan!important;
}

.c-yellow {
    color: $yellow!important;
}

.c-black {
    color: $black!important;
}

.td-underline {
    text-decoration: underline;
}

.c-white {
    color: $white!important;
}

.f-bold {
    color: #000;
    font-weight: bold;
}

.fs-1half {
    font-size: 1.5em;
}

.bc-danger {
    border-color: $danger !important;
}

.bg-cyan {
    background-color: $cyan !important;
}

.bg-magenta {
    background: $magenta !important;
}

.bg-yellow {
    background: $yellow !important;
}

.is-fullheight {
    height: 100%;
}

.is-fullwidth {
    width: 100%;
}

.pt-10 {
    padding-top: 10rem;
}

.mt-3 {
    margin-top: 3rem;
}

.fs-italic {
    font-style: italic;
}

.mb-1 {
    margin-bottom: 1rem;
}

.jc-sb {
    display: flex;
    justify-content: space-between;
}

.ml-auto {
    margin-left: auto;
}

.ml-3 {
    margin-left: 3rem;
}

.is-borderless {
    border: none!important;
}

.icon svg {
    width: 100%;
    height: 100%;

    &.is-small {
        width: 1rem;
        height: 1rem;
    }
}

.rotate-90 {
    transform: rotate(90deg);
}

.cursor-pointer {
    cursor: pointer;
}

@for $i from 1 to 8 {
    .clamp-#{$i} {
        -webkit-line-clamp: #{$i};
    }
}
