<template>
  <div class="column">
    <div class="columns is-centered">
      <div class="column is-4">
        <figure class="image not-found">
          <img src="/images/404.svg" alt="404" />
        </figure>
        <p class="title is-2">
          Nothing BIG over here. <br />We couldn't print this page for you
        </p>
        <button class="button is-primary" @click="$router.go(-1)">
          Go back
        </button>
      </div>
    </div>
  </div>
</template>

<style>
.image.not-found {
  margin-bottom: 5rem;
}
</style>
