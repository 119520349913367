

































































.button.is-category {
  .icon.is-small {
    height: 0.75rem;
    width: 0.75rem;
  }
}
