

























































.modal-content .card {
  overflow: hidden;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  .card-header,
  .card-footer {
    flex-shrink: 0;
  }

  .card-content {
    overflow: auto;
  }
}
