























































@media screen and (max-width: 768px) {
  .help-button {
    bottom: 4rem !important;
  }
  }

.help-button {
  position: fixed;
  right: 1rem;
  bottom: 2rem;
}
