





































































































.frames-selector,
.copies-editor {
  .table td {
    border-width: 0;
  }

  .copy-row__buttons {
    display: flex;
  }
}
