

































































@import "~vars";
@import "~bulma/sass/utilities/mixins";

.finish-preview {
  display: grid;
  grid-template-columns: 1fr minmax(100px, 1fr) 1fr;
  grid-template-rows: 1fr minmax(100px, 4fr) 1fr;
  grid-gap: 1rem;
  align-items: center;
}

.preview-image {
  grid-row: 2;
  grid-column: 2;
  border: 2px #000 solid;
}

.finish-side.top {
  grid-row: 1;
  grid-column: 1 / 4;
  text-align: center;
}

.finish-side.right,.finish-side.left {
  min-width: 0;

  span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.finish-side.right {
  grid-row: 2;
  grid-column: 3;
}

.finish-side.bottom {
  grid-column: 1 / 4;
  grid-row: 3;
  text-align: center;
}

.finish-side.left{
  grid-row: 2;
  grid-column: 1;
  justify-content: flex-end;
  text-align: right;
}
